import type { LbCreateTarget } from "@warrenio/api-spec/spec.oats.gen";
import { useState } from "react";
import { WButton, WSubmitButton } from "../../../components/button/WButton.tsx";
import { FieldButtonGroup } from "../../../components/forms/FieldButtonGroup.tsx";
import { useStandardMutation } from "../../api/useStandardMutation.ts";
import type { VirtualMachineLoc } from "../../compute/vmQuery.ts";
import { TargetServersSelect } from "./TargetServersSelect.tsx";
import { addTargetToLoadBalancerMutation, type LoadBalancerWithType } from "./apiOperations.ts";

export interface AddTargetServerFormProps {
    item: LoadBalancerWithType;
    onClose: () => void;
}

export function AddTargetServerForm({ item, onClose }: AddTargetServerFormProps) {
    const { location, uuid, network_uuid } = item;

    const addTargetMutation = useStandardMutation(addTargetToLoadBalancerMutation);
    const [target, setTarget] = useState<LbCreateTarget>();

    async function onSubmit() {
        if (!target) {
            return;
        }
        await addTargetMutation.mutateAsync({
            location,
            uuid,
            body: target,
        });
        onClose();
    }

    function onChangeTarget(vm: VirtualMachineLoc) {
        setTarget({ target_type: "vm", target_uuid: vm.uuid });
    }

    return (
        <FieldButtonGroup>
            <TargetServersSelect
                defaultOpen={true}
                location={location}
                networkUuid={network_uuid}
                usedResourceUuids={item.targets.map((a) => a.target_uuid)}
                onChange={onChangeTarget}
            />
            <WSubmitButton icon="jp-icon-add" action={async () => await onSubmit()}>
                Add
            </WSubmitButton>
            <WButton action={onClose}>Close</WButton>
        </FieldButtonGroup>
    );
}
