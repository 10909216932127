import RA from "../../../components/forms/Radio.module.css";
import R from "../../../components/forms/Radiogroup.module.css";
import TC from "../../../components/table/Table.module.css";

import type { BillingAccount } from "@warrenio/api-spec/spec.oats.gen";
import { useState } from "react";
import { Radio, RadioGroup } from "react-aria-components";
import { ClipBoardTooltip } from "../../../components/ClipBoardTooltip.tsx";
import ContentPane from "../../../components/ContentPane.tsx";
import { NavigateAfterDelete } from "../../../components/NavigateAfterDelete.tsx";
import { ResourceLink } from "../../../components/ResourceLink.tsx";
import { ResourceWithIcon } from "../../../components/ResourceWithIcon.tsx";
import { Separator } from "../../../components/Separator.tsx";
import { Spacer } from "../../../components/Spacer.tsx";
import { Toolbar } from "../../../components/Toolbar.tsx";
import { WTabs } from "../../../components/WTabs.tsx";
import { DeleteButton } from "../../../components/button/DeleteButton.tsx";
import { WButton } from "../../../components/button/WButton.tsx";
import { MaskIcon } from "../../../components/icon/MaskIcon.tsx";
import { LongDate } from "../../../components/l10n/DateFormat.tsx";
import { DeleteModal } from "../../../components/modal/DeleteModal.tsx";
import { DetailsHolder, DetailsTable } from "../../../components/table/DetailsTable.tsx";
import { DetailsTableBillingAccount } from "../../../components/table/DetailsTableBillingAccount.tsx";
import { DetailsTableIpAddress } from "../../../components/table/DetailsTableIpAddress.tsx";
import { MonthlyCostRow } from "../../../components/table/DetailsTableMonthlyCost.tsx";
import { DetailsTableName } from "../../../components/table/DetailsTableName.tsx";
import { DetailsTableRow } from "../../../components/table/DetailsTableRow.tsx";
import { WTable, WTableBody } from "../../../components/table/WTable.tsx";
import { useDeletableResource } from "../../../utils/query/useDeletableResource.tsx";
import { useQueryResultAtom, useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { getResourceById } from "../../api/resourceTypeException.ts";
import { useStandardMutation } from "../../api/useStandardMutation.ts";
import { LocationDetail } from "../../location/LocationDetail.tsx";
import { getResourceName } from "../../resource/getResourceName.tsx";
import { ResourceStatusBadge } from "../ResourceStatusBadge.tsx";
import { VpcRow } from "../VpcRow.tsx";
import * as ipQuery from "../ipAddress/apiOperations.ts";
import type { AssignedIpAddress } from "../ipAddress/assignable.ts";
import { assignedLbsQueryAtom, type LoadBalancerWithAssigned } from "../ipAddress/joinAssignedQuery.ts";
import { vpcQueryAtom } from "../vpc/apiOperations.ts";
import { AddForwardingRuleForm } from "./AddForwardingRuleForm.tsx";
import { AddTargetServerForm } from "./AddTargetServerForm.tsx";
import {
    changeLoadBalancerBillingAccountMutation,
    deleteLoadBalancerMutation,
    dropForwardingRuleFromLoadBalancerMutation,
    renameLoadBalancerMutation,
    unlinkTargetFromLoadBalancerMutation,
    type LoadBalancerWithType,
} from "./apiOperations.ts";
import { convertTargetType } from "./loadBalancer.logic.ts";
import { lbTargetsAtom } from "./targets.ts";

// NB: Extract the mutation call to a separate function so we can get its exact type via `typeof`
function useDeleteLoadBalancerMutation() {
    return useStandardMutation(deleteLoadBalancerMutation);
}

function DeleteLoadBalancerModal({
    item,
    deleteMutation,
}: {
    item: LoadBalancerWithAssigned;
    deleteMutation: ReturnType<typeof useDeleteLoadBalancerMutation>;
}) {
    //#region Hooks
    const [deleteFloatingIp, setDeleteFloatingIp] = useState<string | undefined>(undefined);
    const deleteIpAddressMutation = useStandardMutation(ipQuery.deleteIpAddressMutation);
    //#endregion

    const { location, uuid, assignedPublicIp: ipAddress } = item;

    async function onDelete() {
        await deleteMutation.mutateAsync({ location, uuid });
        if (deleteFloatingIp === "yes") {
            await deleteIpAddressMutation.mutateAsync({ location, address: ipAddress!.address });
        }
    }

    return (
        <DeleteModal
            title="Delete Load Balancer"
            isActionDisabled={!deleteFloatingIp && !!ipAddress}
            modalAction={async () => await onDelete()}
        >
            <p>This will stop forwarding traffic to the targets and remove the load balancer.</p>

            {!!ipAddress && (
                <>
                    <p className="pb-3">
                        You have a floating IP <b>{ipAddress?.address}</b> assigned to this resource.
                    </p>

                    <p className="font-size-subtitle pb-2">What would you like to do?</p>
                    <RadioGroup
                        className={R.RadioGroup}
                        aria-label="Keep or delete floating IP"
                        value={deleteFloatingIp}
                        orientation="horizontal"
                        onChange={setDeleteFloatingIp}
                    >
                        <Radio className={RA.Radio} value="no">
                            Keep floating IP
                        </Radio>
                        <Radio className={RA.Radio} value="yes">
                            Delete floating IP
                        </Radio>
                    </RadioGroup>
                </>
            )}
        </DeleteModal>
    );
}

export function LoadBalancerView({ location, lbId }: { location: string; lbId: string }) {
    //#region Hooks
    const deleteMutation = useDeleteLoadBalancerMutation();
    const renameMutation = useStandardMutation(renameLoadBalancerMutation);
    const changeBillingAccountMutation = useStandardMutation(changeLoadBalancerBillingAccountMutation);

    const data = useSuspenseQueryAtom(assignedLbsQueryAtom(location));
    const vpcs = useSuspenseQueryAtom(vpcQueryAtom(location));

    const balancer = useDeletableResource(() => getResourceById(data, lbId, "load_balancer"), deleteMutation);
    //#endregion

    if (balancer === undefined) {
        return <NavigateAfterDelete />;
    }

    const { $type, uuid, display_name, private_address, created_at, billing_account_id, network_uuid, price } =
        balancer;

    const vpc = vpcs.get(network_uuid);

    async function changeBillingAccount(billing_account: BillingAccount) {
        await changeBillingAccountMutation.mutateAsync({ location, uuid, billing_account_id: billing_account.id });
    }

    async function renameLoadBalancer(display_name: string) {
        await renameMutation.mutateAsync({ location, uuid, body: { display_name } });
    }

    return (
        <>
            <Toolbar>
                <DeleteLoadBalancerModal item={balancer} deleteMutation={deleteMutation} />
            </Toolbar>

            <ContentPane>
                <h1 className="font-size-heading">{display_name ? display_name : ""}</h1>

                <DetailsHolder>
                    <DetailsTable>
                        <DetailsTableName value={display_name} onChange={async (e) => await renameLoadBalancer(e)} />
                        <DetailsTableRow title="Type:">
                            <ResourceWithIcon type={$type} />
                        </DetailsTableRow>

                        <LoadBalancerPublicIp item={balancer} />

                        <DetailsTableRow title="Private IPv4:">
                            <ClipBoardTooltip>{private_address}</ClipBoardTooltip>
                        </DetailsTableRow>

                        <VpcRow vpc={vpc} />
                    </DetailsTable>

                    <Spacer />

                    <DetailsTable>
                        <DetailsTableRow title="UUID:">
                            <ClipBoardTooltip>{uuid}</ClipBoardTooltip>
                        </DetailsTableRow>
                        <DetailsTableRow title="Created:">
                            <LongDate date={created_at} />
                        </DetailsTableRow>
                        <LocationDetail slug={location} />
                        <DetailsTableRow />
                        <DetailsTableBillingAccount
                            valueKey={billing_account_id}
                            onChange={async (item) => await changeBillingAccount(item.account)}
                        />
                        <MonthlyCostRow price={price} />
                    </DetailsTable>
                </DetailsHolder>
            </ContentPane>

            <Separator />

            <WTabs
                allTab
                items={[
                    { id: "target", title: "Target servers", content: <TargetServersContent obj={balancer} /> },
                    { id: "rules", title: "Forwarding rules", content: <ForwardingRulesContent obj={balancer} /> },
                ]}
            >
                <ContentPane>
                    <h2 className="font-size-subtitle">Description</h2>
                    <p>
                        High availability load balancer distributes traffic between virtual machines within the same
                        data center location.
                    </p>
                </ContentPane>
            </WTabs>
        </>
    );
}

function LoadBalancerPublicIp({ item }: { item: LoadBalancerWithAssigned }) {
    //#region Hooks
    const unAssignIpMutation = useStandardMutation(ipQuery.unassignIpAddressMutation);
    const assignIpAddressMutation = useStandardMutation(ipQuery.assignIpAddressMutation);
    //#endregion

    const { location, private_address, assignedPublicIp } = item;

    async function assignIpAddress(address: AssignedIpAddress | null) {
        if (address) {
            await assignIpAddressMutation.mutateAsync({
                location,
                address: address.address,
                body: { private_ip: private_address },
            });
        } else {
            await unAssignIpMutation.mutateAsync({ location, address: assignedPublicIp!.address });
        }
    }

    return (
        <DetailsTableIpAddress
            value={assignedPublicIp}
            location={location}
            onChange={async (obj) => await assignIpAddress(obj)}
        />
    );
}

function TargetServersContent({ obj }: { obj: LoadBalancerWithType }) {
    //#region Hooks
    const unlinkTargetMutation = useStandardMutation(unlinkTargetFromLoadBalancerMutation);
    const [isVisible, setIsVisible] = useState(false);

    const { data: targetResources } = useQueryResultAtom(lbTargetsAtom);
    //#endregion

    const { location, uuid } = obj;

    async function unlinkTarget(target_uuid: string) {
        await unlinkTargetMutation.mutateAsync({ location, uuid, target_uuid });
    }

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Target servers</h2>

            <WTable
                afterTable={
                    !isVisible && (
                        <WButton
                            color="primary"
                            variant="border"
                            size="bar"
                            icon="jp-icon-add"
                            action={() => setIsVisible(!isVisible)}
                        >
                            Add Target Server
                        </WButton>
                    )
                }
            >
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Private IP</th>
                        <th>Status</th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    {obj.targets.map((item) => {
                        const resource = targetResources?.get(item.target_uuid);
                        const ip = resource?.private_ipv4;

                        return (
                            <tr key={item.target_uuid}>
                                <td>
                                    {resource && <ResourceLink title={getResourceName(resource)} item={resource} />}
                                </td>
                                <td>
                                    <ResourceWithIcon type={convertTargetType(item.target_type)} />
                                </td>
                                <td>{!!ip && <ClipBoardTooltip>{ip}</ClipBoardTooltip>}</td>
                                <td>{!!resource && <ResourceStatusBadge item={resource} />}</td>
                                <td className="text-right">
                                    <DeleteButton
                                        textLabel="Unlink target"
                                        inTable
                                        action={async () => await unlinkTarget(item.target_uuid)}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </WTableBody>
                {isVisible && (
                    <tfoot>
                        <tr>
                            <td colSpan={5}>
                                <AddTargetServerForm item={obj} onClose={() => setIsVisible(false)} />
                            </td>
                        </tr>
                    </tfoot>
                )}
            </WTable>
        </ContentPane>
    );
}

function ForwardingRulesContent({ obj }: { obj: LoadBalancerWithType }) {
    const dropForwardingRuleMutation = useStandardMutation(dropForwardingRuleFromLoadBalancerMutation);

    const { location, uuid } = obj;
    const [isVisible, setIsVisible] = useState(false);

    async function dropRule(rule_uuid: string) {
        await dropForwardingRuleMutation.mutateAsync({ location, uuid, rule_uuid });
    }

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Forwarding rules</h2>

            <WTable
                className={TC.rules}
                afterTable={
                    !isVisible && (
                        <WButton
                            color="primary"
                            variant="border"
                            size="bar"
                            icon="jp-icon-add"
                            action={() => setIsVisible(!isVisible)}
                        >
                            Add New Rule
                        </WButton>
                    )
                }
            >
                <thead>
                    <tr>
                        <th>Protocol</th>
                        <th className="text-right">Port</th>
                        <th />
                        <th>Target port</th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    {obj.forwarding_rules.map((item) => (
                        <tr key={item.uuid}>
                            <td>TCP</td>
                            <td className="text-right">{item.source_port}</td>
                            <td className="text-center">
                                <MaskIcon className="jp-arrow-thin-right-icon size-1.25rem color-muted" />
                            </td>
                            <td>{item.target_port}</td>
                            <td className="text-right">
                                <DeleteButton inTable action={async () => await dropRule(item.uuid)} />
                            </td>
                        </tr>
                    ))}
                </WTableBody>
                {isVisible && (
                    <tfoot>
                        <tr>
                            <AddForwardingRuleForm
                                aria-label="Add/remove forwarding rules"
                                obj={obj}
                                onClose={() => setIsVisible(false)}
                            />
                        </tr>
                    </tfoot>
                )}
            </WTable>
        </ContentPane>
    );
}
