import { createLazyFileRoute } from "@tanstack/react-router";
import { LoadBalancerView } from "../../../../../../modules/network/loadbalancer/LoadBalancerView.tsx";

export const Route = createLazyFileRoute("/_main/network/load_balancer/$location/$uuid/")({
    component: Component,
});

function Component() {
    const { uuid, location } = Route.useParams();
    // NB: `key` prop ensures that the component state is reset when the route changes (to prevent eg. `isOpen` etc. states from remaining the same)
    return <LoadBalancerView key={uuid} location={location} lbId={uuid} />;
}
