import T from "../../../components/forms/TextField.module.css";

import { useState } from "react";
import { FieldError, Input, NumberField } from "react-aria-components";
import { WButton, WSubmitButton } from "../../../components/button/WButton.tsx";
import { MaskIcon } from "../../../components/icon/MaskIcon.tsx";
import { useStandardMutation } from "../../api/useStandardMutation.ts";
import type { RulesProps } from "./ForwardingRulesList.tsx";
import { addForwardingRuleToLoadBalancerMutation, type LoadBalancerWithType } from "./apiOperations.ts";

interface AddForwardingRuleFormProps {
    obj: LoadBalancerWithType;
    onClose: () => void;
}

export function AddForwardingRuleForm({ obj, onClose }: AddForwardingRuleFormProps) {
    const addForwardingRuleMutation = useStandardMutation(addForwardingRuleToLoadBalancerMutation);

    const { location, uuid } = obj;

    const [forwardingRule, setForwardingRule] = useState<RulesProps>({ source_port: NaN, target_port: NaN });

    async function onSubmit() {
        if (
            validatePort(forwardingRule.source_port) === undefined &&
            validatePort(forwardingRule.target_port) === undefined
        ) {
            await addForwardingRuleMutation.mutateAsync({
                location,
                uuid,
                body: { ...forwardingRule },
            });
            onClose();
        }
    }

    function validatePort(value: number) {
        return !(value > 0 && value < 65536) ? "Port must be between 1 and 65535." : undefined;
    }

    function validateSourcePort(value: number) {
        const found = obj.forwarding_rules.find((item) => item.source_port === value);
        return found ? "Source port must be unique" : validatePort(value);
    }

    return (
        <>
            <td>TCP</td>
            <td className="text-right">
                <NumberField
                    className={T.NumberField}
                    onChange={(e) => setForwardingRule({ ...forwardingRule, source_port: e })}
                    name="source_port"
                    defaultValue={NaN}
                    aria-label="Set source port value"
                    isRequired
                    validate={validateSourcePort}
                    formatOptions={{
                        useGrouping: false,
                    }}
                >
                    <div>
                        <Input className={T.Input} />
                    </div>
                    <FieldError />
                </NumberField>
            </td>
            <td className="text-center">
                <MaskIcon className="jp-arrow-thin-right-icon size-1.25rem color-muted" />
            </td>
            <td>
                <NumberField
                    className={T.NumberField}
                    onChange={(e) => setForwardingRule({ ...forwardingRule, target_port: e })}
                    name="target_port"
                    defaultValue={NaN}
                    aria-label="Set target port value"
                    isRequired
                    validate={validatePort}
                    formatOptions={{
                        useGrouping: false,
                    }}
                >
                    <div>
                        <Input className={T.Input} />
                    </div>
                    <FieldError />
                </NumberField>
            </td>
            <td className="text-right">
                <div className="flex gap-0.5em justify-end">
                    <WSubmitButton icon="jp-icon-add" action={async () => await onSubmit()}>
                        Add
                    </WSubmitButton>
                    <WButton action={onClose}>Close</WButton>
                </div>
            </td>
        </>
    );
}
